:root {
  --header-height: 150px;
  --header-height-collapse: 75px;
}

.App {
  text-align: center;
}

.App-logo {
  align-self: flex-start;
  justify-self: flex-start;
  display: block;
  max-height: 100%;
  width: var(--header-height);

}

footer {
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;

}

h1, h2, h3, h4 {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
}

footer>div {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex: 1;
  text-align: left;
  padding: 20px;
}

footer>div:nth-child(2) {
  align-items: center;
  justify-content: flex-end;
  padding: 0;
}

.menu {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: var(--header-height);
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.backgroundImage {
  background-size: cover;
  background-position: center;
  min-height: 75vh;

}

.two-column {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 20px;
  width: 100%;
  margin: 20px 0;
  overflow: hidden;
}

hr {
  content: "";
  width: 75%;
  height: 1px;
  left: 12.5%;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1)
}

.two-column>div {
  flex: 1;
  padding: 20px;
  text-align: left;
  line-height: 1.26;
  align-self: center;
}

.two-column>div:not(.backgroundImage) {
  padding: 40px;
}

.two-column>div.center {

  text-align: center;
  max-width: 750px;

}

ul {
  list-style-type: none;
  padding: 20px;
}

ul li:before {
  content: "-";
  margin-right: 10px;
}

.hero {
  background-image: url("./city.webp");
  height: 80vh;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  padding: 0 25px;

}

.hero::after {
  content: "";
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 40px;
  z-index: 1;
}

.hero h1 {
  font-size: 3em;
  z-index: 2;
}

.title {
  flex: 1;
  display: flex;
  justify-content: center;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;

}

.header {
  background-color: rgba(255, 255, 255, 0.8);
  height: var(--header-height);
  display: flex;
  flex-direction: row;
  align-items: center;

  font-size: calc(10px + 2vmin);
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 100;
  backdrop-filter: blur(12px);
  transition: all 0.3s;
}

.header.collapse {
  height: var(--header-height-collapse);
  background-color: rgba(124, 124, 124, 0.8);
  color: white;
}

footer, header {
  padding: 0 20px;
}

.main {

  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding-top: var(--header-height);
  font-size: calc(10px + 1vmin);
  color: rgb(27, 25, 25);
  max-width: 1600px;
  margin: 0 auto;
}

.App-link {
  color: #61dafb;
}

@media only screen and (max-width: 720px) {
  .two-column {
    flex-direction: column;
  }

  .two-column>div {
    order: 2;
  }

  .two-column>div.backgroundImage {
    min-height: 45vh;
    width: 100%;
    order: 1;
  }

  footer {
    flex-direction: column;
  }

  .header {
    height: var(--header-height-collapse);
  }

  .App-logo, .menu {
    width: var(--header-height-collapse);
  }
}